<template>
  <div class="c-app flex-row align-items-center bg-yellow">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font160 text-center" style="margin-top: 20px">
          <strong>รายงานการอัพเดทระเบียนประวัติ</strong></CCol
        >
        <CCol
          lg="12"
          class="text-center text-white"
          style="margin-bottom: 20px"
        >
          <div v-if="rows">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :search-options="{ enabled: true }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'level_abv_name', type: 'asc' },
              }"
              :pagination-options="{
                enabled: true,
                mode: 'page',
                perPage: 50,
              }"
            >
              >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'std_district'">
                  <span
                    class="badge badge-success"
                    v-if="props.row.std_district"
                    >District OK</span
                  >
                  <router-link
                    :to="{ name: 'Profile' }"
                    class="badge badge-primary"
                    v-else
                    >กรอกเดี๋ยวนี้</router-link
                  >
                </div>
                <div v-else-if="props.column.field == 'std_tel'">
                  <span class="badge badge-success" v-if="props.row.std_tel"
                    >Student Tel OK</span
                  >
                  <router-link
                    :to="{ name: 'Profile' }"
                    class="badge badge-primary"
                    v-else
                    >กรอกเดี๋ยวนี้</router-link
                  >
                </div>
                <div v-else-if="props.column.field == 'std_ptel'">
                  <span class="badge badge-success" v-if="props.row.std_ptel"
                    >Parent Tel OK</span
                  >
                  <router-link
                    :to="{ name: 'Profile' }"
                    class="badge badge-primary"
                    v-else
                    >กรอกเดี๋ยวนี้</router-link
                  >
                </div>
                <div v-else-if="props.column.field == 'std_lat'">
                  <span class="badge badge-success" v-if="props.row.std_lat"
                    >GPS OK</span
                  >
                  <router-link
                    :to="{ name: 'Profile' }"
                    class="badge badge-primary"
                    v-else
                    >กรอกเดี๋ยวนี้</router-link
                  >
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <div v-else>Loading...</div>
        </CCol>
        <CCol
          lg="12"
          class="text-center text-white"
          style="margin-bottom: 20px"
        >
          <CCard>
            <CCardHeader class="text-black">สรุปรายห้อง</CCardHeader>
            <CCardBody>
              <div v-if="rowsCompare">
                <vue-good-table
                  :columns="columnsCompare"
                  :rows="rowsCompare"
                  :line-numbers="true"
                  :search-options="{ enabled: true }"
                  :sort-options="{
                    enabled: true,
                    initialSortBy: { field: 'level_fullName', type: 'asc' },
                  }"
                  :pagination-options="{
                    enabled: true,
                    mode: 'page',
                    perPage: 20,
                  }"
                  theme="nocturnal"
                >
                  >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field == 'level_id'">
                      {{
                        (
                          (props.row.total_updated_gps /
                            props.row.total_in_room) *
                          100
                        ).toFixed(2)
                      }}
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
              <div v-else>Loading...</div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg="12">
          <CChartPie
            style="height: 300px"
            :datasets="[
              {
                data: [updatedPercent,remainUpdate],
                backgroundColor: ['#E55353', '#000E00'],
                label: 'กรอกแล้ว',
              },
            ]"
            :labels="['กรอกแล้ว', 'ยังไม่กรอก']"
            :options="{ maintainAspectRatio: false }"
          />

        </CCol>
      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "Login",
  components: { VueGoodTable },
  data() {
    return {
      darkModal: false,
      columns: [
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: ["ม.1", "ม.2", "ม.3", "ม.4", "ม.5", "ม.6"], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: "ห้อง",
          field: "std_class",
          type: "number",
        },
        {
          label: "ตำบล",
          field: "std_district",
        },
        {
          label: "เบอร์โทรของนักเรียน",
          field: "std_tel",
        },
        {
          label: "เบอร์โทรของผู้ปกครอง",
          field: "std_ptel",
        },
        {
          label: "GPS",
          field: "std_lat",
        },
      ],
      columnsCompare: [
        {
          label: "ชั้น",
          field: "level_fullName",
          filterOptions: {
            enabled: true, // enable filter for this column
            filterDropdownItems: [
              "มัธยมศึกษาปีที่ 1",
              "มัธยมศึกษาปีที่ 2",
              "มัธยมศึกษาปีที่ 3",
              "มัธยมศึกษาปีที่ 4",
              "มัธยมศึกษาปีที่ 5",
              "มัธยมศึกษาปีที่ 6",
            ], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: "ห้อง",
          field: "std_class",
        },
        {
          label: "ทั้งหมด (คน)",
          field: "total_in_room",
          type: "number",
        },
        {
          label: "กรอกแล้ว (คน)",
          field: "total_updated_gps",
          type: "number",
        },
        {
          label: "ร้อยละ",
          field: this.percent,
          type: "percentage",
        },
      ],
      rows: null,
      rowsCompare: null,
      totalUpdated: 0,
      totalStd: 0,
      remainUpdate:0,
      updatedPercent:0,
      backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
      data: [40, 20, 80, 10],
    };
  },
  mounted() {
    let schoolCode = this.$route.params.schoolCode;

    //FETCH UPDATED PROFILE
    this.axios
      .get(
        this.$hostUrl +
          "php_action/recordAPI.php?txtAction=getNullLat&schoolCode=" +
          schoolCode
      )
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
      });

    //FETCH COMPARE UPDATED
    this.axios
      .get(
        this.$hostUrl +
          "php_action/recordAPI.php?txtAction=getCompareGPS_Update&schoolCode=" +
          schoolCode
      )
      .then((response) => {
        //console.log(response.data);
        this.rowsCompare = response.data.mainData;
        this.rowsCompare.forEach((element) => {
          this.totalUpdated =
            this.totalUpdated + parseInt(element.total_updated_gps);
          this.totalStd = this.totalStd + parseInt(element.total_in_room);
        });
        this.updatedPercent = (this.totalUpdated / this.totalStd) * 100; 
        this.remainUpdate = 100-this.updatedPercent;
        //console.log((this.totalUpdated / this.totalStd) * 100);
        
      });
  },
  methods: {
    roughSizeOfObject(object) {
      var objectList = [];
      var stack = [object];
      var bytes = 0;

      while (stack.length) {
        var value = stack.pop();

        if (typeof value === "boolean") {
          bytes += 4;
        } else if (typeof value === "string") {
          bytes += value.length * 2;
        } else if (typeof value === "number") {
          bytes += 8;
        } else if (
          typeof value === "object" &&
          objectList.indexOf(value) === -1
        ) {
          objectList.push(value);

          for (var i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },
    percent(rowObj) {
      return rowObj.total_updated_gps / rowObj.total_in_room;
    },
  },
};
</script>
