var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app flex-row align-items-center bg-yellow"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"font160 text-center",staticStyle:{"margin-top":"20px"},attrs:{"lg":"12"}},[_c('strong',[_vm._v("รายงานการอัพเดทระเบียนประวัติ")])]),_c('CCol',{staticClass:"text-center text-white",staticStyle:{"margin-bottom":"20px"},attrs:{"lg":"12"}},[(_vm.rows)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"search-options":{ enabled: true },"sort-options":{
              enabled: true,
              initialSortBy: { field: 'level_abv_name', type: 'asc' },
            },"pagination-options":{
              enabled: true,
              mode: 'page',
              perPage: 50,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'std_district')?_c('div',[(props.row.std_district)?_c('span',{staticClass:"badge badge-success"},[_vm._v("District OK")]):_c('router-link',{staticClass:"badge badge-primary",attrs:{"to":{ name: 'Profile' }}},[_vm._v("กรอกเดี๋ยวนี้")])],1):(props.column.field == 'std_tel')?_c('div',[(props.row.std_tel)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Student Tel OK")]):_c('router-link',{staticClass:"badge badge-primary",attrs:{"to":{ name: 'Profile' }}},[_vm._v("กรอกเดี๋ยวนี้")])],1):(props.column.field == 'std_ptel')?_c('div',[(props.row.std_ptel)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Parent Tel OK")]):_c('router-link',{staticClass:"badge badge-primary",attrs:{"to":{ name: 'Profile' }}},[_vm._v("กรอกเดี๋ยวนี้")])],1):(props.column.field == 'std_lat')?_c('div',[(props.row.std_lat)?_c('span',{staticClass:"badge badge-success"},[_vm._v("GPS OK")]):_c('router-link',{staticClass:"badge badge-primary",attrs:{"to":{ name: 'Profile' }}},[_vm._v("กรอกเดี๋ยวนี้")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3466283207)},[_vm._v(" > ")])],1):_c('div',[_vm._v("Loading...")])]),_c('CCol',{staticClass:"text-center text-white",staticStyle:{"margin-bottom":"20px"},attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-black"},[_vm._v("สรุปรายห้อง")]),_c('CCardBody',[(_vm.rowsCompare)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columnsCompare,"rows":_vm.rowsCompare,"line-numbers":true,"search-options":{ enabled: true },"sort-options":{
                  enabled: true,
                  initialSortBy: { field: 'level_fullName', type: 'asc' },
                },"pagination-options":{
                  enabled: true,
                  mode: 'page',
                  perPage: 20,
                },"theme":"nocturnal"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'level_id')?_c('div',[_vm._v(" "+_vm._s(( (props.row.total_updated_gps / props.row.total_in_room) * 100 ).toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3337122126)},[_vm._v(" > ")])],1):_c('div',[_vm._v("Loading...")])])],1)],1),_c('CCol',{attrs:{"lg":"12"}},[_c('CChartPie',{staticStyle:{"height":"300px"},attrs:{"datasets":[
            {
              data: [_vm.updatedPercent,_vm.remainUpdate],
              backgroundColor: ['#E55353', '#000E00'],
              label: 'กรอกแล้ว',
            },
          ],"labels":['กรอกแล้ว', 'ยังไม่กรอก'],"options":{ maintainAspectRatio: false }}})],1)],1)],1),_c('CModal',{attrs:{"show":_vm.darkModal,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2","size":"lg","color":"dark"},on:{"update:show":function($event){_vm.darkModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("คำเตือน!!")]),_c('CButtonClose',{staticClass:"text-white",on:{"click":function($event){_vm.darkModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.darkModal = false}}},[_vm._v("Discard")])]},proxy:true}])},[_vm._v(" password หรือ username ไม่ถูกต้อง ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }